import { defineStore } from 'pinia';
import { ref } from 'vue';
import apiClient from '@plugins/api-client';
import { getApiRoot } from '@/helpers/api';
import { getKeycloak } from '@/helpers/keycloak';
import { useI18n } from 'vue-i18n';

export const InvoiceStatuses = {
  Paid: 1,
  Open: 0,
};

export const useInvoiceStore = defineStore('invoiceStore', () => {
  const invoices = ref([]);
  const invoice = ref();
  const { t } = useI18n();

  async function getInvoiceAndRelatedInvoicesByID(id) {
    invoices.value = [];
    try {
      const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
      const response = await apiClient.get(
        `${getApiRoot()}/invoices/${id}/related`,
        { headers },
      );
      if (response.data.errors.length === 0) {
        let result = response.data.data;
        invoices.value.push({
          invoiceId: id,
          invoiceNr: result.invoice.invoiceNr,
          invoiceNrPrefix: result.invoice.invoiceNrPrefix,
          currentStatusId: result.invoice.paymentComplete,
          isPrepaymentInvoice: false,
        });
        result.relatedInvoices.forEach((relatedInvoice) => {
          invoices.value.push({
            invoiceId: relatedInvoice.invoiceId,
            invoiceNr: relatedInvoice.invoiceNr,
            invoiceNrPrefix: relatedInvoice.invoiceNrPrefix,
            currentStatusId: relatedInvoice.paymentComplete,
            isPrepaymentInvoice: relatedInvoice.isPrepaymentInvoice,
          });
        });
      } else {
        throw new Error(response.data.errors);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  async function getInvoiceByID(id) {
    try {
      const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
      const response = await apiClient.get(
        `${getApiRoot()}/invoices/${id}`,
        { headers },
      );
      if (response.data.errors.length === 0) {
        invoice.value = response.data.data;
      } else {
        throw new Error(response.data.errors);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  function invoiceTitle(invoice) {
    let title = (invoice.isPrepaymentInvoice ? t('orderDetail.prepayment') : t('invoice')) + ' ';

    if (invoice.invoiceNrPrefix) {
      title += invoice.invoiceNrPrefix;
    }

    title += invoice.invoiceNr;

    return title;
  }

  return {
    invoice,
    invoices,
    getInvoiceAndRelatedInvoicesByID,
    getInvoiceByID,
    invoiceTitle,
  };
});
