import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getApiRoot } from '@/helpers/api';
import apiClient from '@plugins/api-client';
import { getLocalstorageItem, setLocalstorageItem } from '@/helpers/localStorage';
import { getSubdomain } from '@/helpers/util';
import { getKeycloak } from '@/helpers/keycloak';

export const DeliveryStatuses = {
  New: 1,
  On_Transport_Order: 2,
  Rambours: 3,
  Completed: 4,
  Prepared: 6,
  Collected: 7,
};

export const useDeliveryStore = defineStore('deliveryStore', () => {
  const delivery = ref(null);
  const StatusExpirationDays = 10;

  async function getDeliveryByID(id) {
    try {
      const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
      const response = await apiClient.get(
        `${getApiRoot()}/deliveries/${id}`,
        { headers },
      );
      if (response.data.errors.length === 0) {
        delivery.value = response.data.data;
      } else {
        throw new Error(response.data.errors);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  async function getDeliveryStatuses() {
    let DeliveryStatuses = getLocalstorageItem('DeliveryStatuses');
    if (DeliveryStatuses !== undefined) {
      return DeliveryStatuses;
    }
    return (await parseStatusResponse())?.DeliveryStatuses;
  }

  async function getInitialDeliveryStatus() {
    let InitialDeliveryStatus = getLocalstorageItem('InitialDeliveryStatus');
    if (InitialDeliveryStatus !== undefined) {
      return InitialDeliveryStatus;
    }
    return (await parseStatusResponse())?.InitialDeliveryStatus;
  }

  async function getFinalDeliveryStatus() {
    let FinalDeliveryStatus = getLocalstorageItem('FinalDeliveryStatus');
    if (FinalDeliveryStatus !== undefined) {
      return FinalDeliveryStatus;
    }
    return (await parseStatusResponse())?.FinalDeliveryStatus;
  }

  async function parseStatusResponse() {
    try {
      const response = await apiClient.get(
        `${getApiRoot()}/deliveries/statuses`, {
          params: {
            subDomain: getSubdomain(),
            lang: 'nl-BE',
          },
        },
      );
      if (response.data.errors.length === 0) {
        let DeliveryStatuses = {};
        let InitialDeliveryStatus = null;
        let FinalDeliveryStatus = null;
        const data = response.data.data;
        for (const status of data) {
          DeliveryStatuses[status.status.split(' ').join('_')] = status.id;
        }
        InitialDeliveryStatus = data.filter((status) => status.initial)[0]?.id;
        FinalDeliveryStatus = data.filter((status) => status.final)[0]?.id;

        setLocalstorageItem('DeliveryStatuses', DeliveryStatuses, StatusExpirationDays);
        setLocalstorageItem('InitialDeliveryStatus', InitialDeliveryStatus, StatusExpirationDays);
        setLocalstorageItem('FinalDeliveryStatus', FinalDeliveryStatus, StatusExpirationDays);

        return {
          DeliveryStatuses: DeliveryStatuses,
          InitialDeliveryStatus: InitialDeliveryStatus,
          FinalDeliveryStatus: FinalDeliveryStatus,
        };
      } else {
        throw new Error(response.data.errors);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  return {
    delivery,
    getDeliveryByID,
    getDeliveryStatuses,
    getInitialDeliveryStatus,
    getFinalDeliveryStatus,
  };
});
