/**
 * Format number to decimal string (default to a maximum of 2 decimals)
 * @param num
 * @param maximumFractionDigits
 * @param locale
 * @returns {string|*}
 */
export function numberToDecimalString(num, maximumFractionDigits = 2, locale = 'default') {
  return toLocaleString(num, { maximumFractionDigits }, locale);
}

/**
 * Format number as currency.
 * @param num
 * @param currency
 * @param locale
 * @returns {string}
 */
export function numberToCurrencyString(num, currency = 'EUR', locale = 'default') {
  return toLocaleString(num, {
    style: 'currency',
    currency,
  }, locale);
}

/**
 * Wrapper for js toLocaleString method.
 * @param num
 * @param options
 * @param locale
 * @returns {string|*}
 */
export function toLocaleString(num, options = {}, locale = 'default') {
  return !isNaN(Number(num)) ? Number(num).toLocaleString(locale, options) : num;
}

export const sum = (a, b) => a + b;
