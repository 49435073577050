import Keycloak from 'keycloak-js';
import { getSubdomain } from '@/helpers/util';

let keycloakInstance = null;
const timeLeftBeforeRefresh = 60 * 15;

export const initKeycloak = () => {
  keycloakInstance = new Keycloak({
    url: import.meta.env.VITE_KEYCLOAK_URL,
    realm: import.meta.env.VITE_KEYCLOAK_REALM,
    clientId: `portal-${getSubdomain().toLowerCase()}`,
  });

  return keycloakInstance.init({ onLoad: 'login-required' });
};

/**
 * @returns {Promise<Keycloak>}
 */
export const getKeycloak = async () => {
  if (keycloakInstance === null) {
    throw Error('Keycloak instance used before it was initialized.');
  }

  try {
    await keycloakInstance.updateToken(timeLeftBeforeRefresh);
  } catch (error) {
    console.log(error);
    throw Error('Failed to refresh token');
  }
  return keycloakInstance;
};
