<template>
  <v-btn size="x-small" class="pa-0 flex-1-1-0" @click="navigateTo(name)">
    <div>
      <v-icon color="base0" :icon="routeActive(name) ? `$${name}_selected` : `$${name}`" />
      <p :class="{ 'font-weight-bold text-primary' : routeActive(name) }">
        {{ $t(`bottomNav.${name}`) }}
      </p>
    </div>
  </v-btn>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  navigates: {
    type: Boolean,
    required: false,
    default: true,
  },
  active: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const router = useRouter();
const route = useRoute();

function navigateTo() {
  if (props.navigates) {
    router.push({ name: props.name });
  }
}

function routeActive() {
  return route.matched.some((match) => match.name === props.name) || props.active;
}
</script>

<style scoped>
p {
    font-size: 0.9em;
    color: rgb(var(--v-theme-base400))
}
</style>
