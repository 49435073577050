import { defineStore } from 'pinia';
import i18n from '@/i18n/index';
import router from '@/router';
import { InstallationStatuses } from './installations';
import { useArticleStore } from './articles';
import { OfferTypes } from './offers';
import { ref } from 'vue';
import { InvoiceStatuses } from '@/store/invoices';
import { useApi } from '@/composables/useApi';
import { getSubdomain } from '@/helpers/util';

const { t } = i18n.global;

export const useAppStore = defineStore('appStore', () => {
  const { apiGet } = useApi();

  const pageLoading = ref(true);

  const articleStore = useArticleStore();
  let { getArticleByGroupType } = articleStore;

  const config = ref([]);

  const validActions = ref([]);

  async function getActions(installation) {
    return [
      {
        name: 'signOffer',
        title: `${t('actions.signOfferTitle')} ${t('with')} ${getActionButtonTitleText(installation)}`,
        buttonText: t('actions.signOfferButtonText'),
        priority: 3,
        action: () => {
          router.push(
            {
              name: installation.OfferVersionNumbers.split(',').length > 1 ? 'offerDetailList' : 'offerDetail',
              params: installation.OfferVersionNumbers.split(',').length > 1
                ? {
                    id: installation.OfferID,
                  }
                : {
                    id: installation.OfferID,
                    version: installation.OfferVersionNumbers,
                  },
            });
        },
        condition: installation.status === InstallationStatuses.New && installation.OfferID > 0,
      },
      {
        name: 'prepay',
        title: `${t('actions.payPrepaymentTitle')} ${t('with')} ${getActionButtonTitleText(installation)}`,
        buttonText: t('actions.payPrepaymentButtonText'),
        priority: 2,
        action: () => {
          router.push({ name: 'orderDetail', params: { installation_id: installation.id, id: installation.OrderID } });
        },
        condition: installation.status === InstallationStatuses.Needs_Prepayment,
      },
      {
        name: 'payfinal',
        title: `${t('actions.payFinalInvoiceTitle')} ${t('with')} ${getActionButtonTitleText(installation)}`,
        buttonText: t('actions.payFinalInvoiceButtonText'),
        priority: 1,
        action: () => {
          router.push({
            name: 'invoiceDetail',
            params: { installation_id: installation.id, id: installation.InvoiceID },
          });
        },
        condition: installation.status === InstallationStatuses.Delivered && installation.InvoiceID > 0,
      },
      {
        name: 'fillInSurvey',
        title: `${t('actions.surveyTitle')} ${installation.OrderID}`,
        buttonText: t('actions.survey'),
        priority: 0,
        action: () => {
          router.push({
            name: 'installations_survey',
            params: { installation_id: installation.id, order_id: installation.OrderID },
          });
        },
        condition: installation.InvoiceStatus === InvoiceStatuses.Paid && installation.InvoiceID > 0 && !installation.Surveyed,
      },
      {
        name: 'signIChoosr',
        title: `${t('actions.iChoosrTitle')} ${t('for')} ${t('orderDetail.order') + ' ' + installation.OrderID}`,
        buttonText: t('actions.iChoosr'),
        priority: 0.5,
        action: () => {
          router.push({ name: 'ichoosr', params: { installation_id: installation.id, id: installation.OrderID } });
        },
        condition: installation.HasExternalPrepayment && installation.QR_FilledIn && !installation.OpleveringDone,
      },
    ];
  }

  async function setDynamicCardActions(installations) {
    const dynamicActions = [];

    for (const installation of installations) {
      const actions = await getActions(installation);
      for (const action of actions) {
        if (action.condition) {
          dynamicActions.push(action);
        }
      }
    }

    if (dynamicActions.length === 0) {
      dynamicActions.push({
        name: 'friendsForFriends',
        title: `${t('actions.friendsForFriendsTitle')}`,
        buttonText: t('actions.friendsForFriends'),
        priority: 0,
        action: () => {
          router.push({ name: 'loyalty' });
        },
        condition: true,
      });
    }

    // Sort actions by priority and pick the first
    dynamicActions.sort((a, b) => b.priority - a.priority);

    validActions.value = dynamicActions;
  }

  function getActionButtonTitleText(installation) {
    let article = '/';
    switch (installation.Offer_TypeID) {
      case OfferTypes.SolarPanels:
        article = getArticleByGroupType(installation.articles.solarPanels, false);
        break;
      case OfferTypes.Batteries:
        article = getArticleByGroupType(installation.articles.batteries, false);
        break;
      case OfferTypes.ChargingStations:
        article = getArticleByGroupType(installation.articles.chargingStations, false);
        break;
      case OfferTypes.HeatPumps:
        article = getArticleByGroupType(installation.articles.heatpumps, false);
        break;
    }
    return article;
  }

  async function getAppConfig() {
    config.value = await apiGet('portal/config?sudbomain=' + getSubdomain());

    return config.value;
  }

  function getFromConfig(keyString) {
    if (!keyString) {
      return;
    }

    const keys = keyString.split('.');

    return keys.reduce((acc, key) => acc?.[key], config.value);
  }

  return {
    pageLoading,
    validActions,
    setDynamicCardActions,
    getAppConfig,
    getFromConfig,
  };
});
