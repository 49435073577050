<script setup>

import router from '@/router';
import SelectMonitoringDevice from '@/components/navbartop/SelectMonitoringDevice.vue';
import { useUserStore } from '@/store/user';
import { useNotificationStore } from '@/store/notifications';
import { useInstallationDocuments } from '@/composables/useInstallationDocuments';
import { storeToRefs } from 'pinia';
import { useTopNavBarStore } from '@/store/topNavBar';

const { download } = useInstallationDocuments();

const userStore = useUserStore();
const { logoutUser } = userStore;

const store = useTopNavBarStore();

const { toggleModal } = store;
const {
  leftNav,
  leftNavCustomIcon,
  navTitle,
  rightNav,
  downloadDocument,
  backFunction,
} = storeToRefs(store);

const { hasNewNotifications } = storeToRefs(useNotificationStore());

function logout() {
  logoutUser();
}
</script>

<template>
  <v-app-bar id="navbartop" :elevation="0" class="d-flex align-content-space-evenly px-2">
    <!-- Left Side -->
    <template v-if="leftNav == 'back'" #prepend>
      <v-btn
        size="x-small"
        variant="tonal"
        icon="mdi-arrow-left"
        @click="backFunction"
      />
    </template>
    <template v-else-if="leftNav == 'close'" #prepend>
      <v-btn
        size="x-small"
        variant="tonal"
        icon="mdi-close"
      />
    </template>
    <template v-else-if="leftNav == 'profile'" #prepend>
      <v-menu>
        <template #activator="{props}">
          <v-btn
            v-bind="props"
            size="x-small"
            variant="tonal"
            icon="mdi-account-circle-outline"
          />
        </template>
        <v-list>
          <v-list-item v-ripple @click="router.push({ name: 'account' })">
            <v-list-item-title>{{ $t('bottomNav.account') }}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item v-ripple @click="logout()">
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-else-if="leftNav === 'modal'" #prepend>
      <v-btn
        size="x-small"
        variant="tonal"
        :icon="leftNavCustomIcon ?? 'mdi-information-outline'"
        @click="toggleModal"
      />
    </template>

    <!-- Title -->
    <v-app-bar-title>
      <p class="small-text font-weight-medium">
        {{ navTitle }}
      </p>
    </v-app-bar-title>

    <!-- Right side -->
    <template v-if="rightNav == 'home'" #append>
      <div class="pr-1">
        <v-btn
          size="x-small"
          variant="tonal"
          icon="mdi-gift-outline"
          @click="router.push({ name: 'loyalty', state: { backPath: router.currentRoute.value.path } })"
        />
      </div>
      <div>
        <v-badge
          v-model="hasNewNotifications"
          dot
          color="primary"
          location="top start"
        >
          <v-btn
            size="x-small"
            variant="tonal"
            icon="mdi-bell-outline"
            @click="router.push({ name: 'notifications', state: { backPath: router.currentRoute.value.path } })"
          />
        </v-badge>
      </div>
    </template>
    <template v-else-if="rightNav == 'options-close'" #append>
      <!--
            <div class="pr-1">
                <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-dots-vertical"
                ></v-btn>
            </div>
            -->
      <div>
        <v-btn
          size="x-small"
          variant="tonal"
          icon="mdi-close"
        />
      </div>
    </template>
    <template v-else-if="rightNav == 'options'" #append>
      <!--
            <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-dots-vertical"
            ></v-btn>
            -->
    </template>
    <template v-else-if="rightNav == 'share-download'" #append>
      <!--
            <div class="pr-1">
                <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-share-variant-outline"
                ></v-btn>
            </div>
            -->
      <div>
        <v-btn
          size="x-small"
          variant="tonal"
          icon="mdi-tray-arrow-down"
          :loading="downloadDocument.downloading"
          @click="() => download(downloadDocument)"
        >
          <template #loader>
            <v-progress-circular color="base800" size="20" width="1" indeterminate />
          </template>
        </v-btn>
      </div>
    </template>
    <template v-else-if="rightNav == 'download'" #append>
      <v-btn
        size="x-small"
        variant="tonal"
        icon="mdi-tray-arrow-down"
        :loading="downloadDocument.downloading"
        @click="() => download(downloadDocument)"
      >
        <template #loader>
          <v-progress-circular color="base800" size="20" width="1" indeterminate />
        </template>
      </v-btn>
    </template>
    <template v-else-if="rightNav === 'loyalty'" #append>
      <v-btn
        size="x-small"
        variant="tonal"
        icon="mdi-tray-gift-outline"
        @click="router.push({ name: 'loyalty', state: { backPath: router.currentRoute.value.path } })"
      />
    </template>
    <template v-else-if="rightNav === 'monitoring'" #append>
      <select-monitoring-device />
    </template>
  </v-app-bar>
</template>

<style scoped>
.small-text {
  font-size: 1.1rem !important;
}
</style>
