import { getApiRoot } from '@/helpers/api';
import { defineStore } from 'pinia';
import apiClient from '@plugins/api-client';
import i18n from '@i18n/index';
import { getKeycloak } from '@/helpers/keycloak';
import { OfferTypes } from './offers';
import { numberToDecimalString } from '@/helpers/numbers';

export const ArticleGroupTypes_backup = {
  Panels: 1,
  SolarBoilerCollectors: 2,
  Inverters: 3,
  Optimizers: 4,
  SolarBoilerSystem: 5,
  Heatpump: 6,
  HeatpumpBoiler: 7,
  Guarantees: 8,
  PostalCodeDependant: 9,
  Accesories: 14,
  BatteryInverters: 15,
  BatteryAccesories: 16,
  Batteries: 17,
  Inspections: 18,
  ChargingStations: 25,
};

const { t } = i18n.global;

export const useArticleStore = defineStore('articleStore', () => {
  async function getArticlesData(articleIDs) {
    try {
      const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
      const response = await apiClient.get(
        getApiRoot() + '/management/articles/' + articleIDs.join(',') + '/?lang=nl-BE',
        { headers },
      );
      if (response.data.errors.length === 0) {
        return response.data.data;
      } else {
        throw new Error(response.data.errors);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  function getArticleByGroupType(articles, lineBreak = true) {
    const newline = lineBreak ? '</br>' : ` ${t('and')} `;
    if (articles === null) {
      return '/';
    }
    let articleString = '';
    articles.forEach((article) => {
      articleString += article.quantity > 1 ? article.quantity + ' x ' + article.descriptionLanguageText : article.descriptionLanguageText;
      articleString += newline;
    });
    return articleString === '' ? '/' : articleString.substring(0, articleString.length - newline.length);
  }

  function getArticleByOfferType(articles, offerType, linebreak = true) {
    let offerTypeArticles = [];
    if (offerType === OfferTypes.SolarPanels) {
      offerTypeArticles = articles.solarPanels;
    } else if (offerType === OfferTypes.Batteries) {
      offerTypeArticles = articles.batteries;
    } else if (offerType === OfferTypes.ChargingStations) {
      offerTypeArticles = articles.chargingStations;
    } else {
      offerTypeArticles = Object.values(articles).find((articleGroup) => articleGroup.length > 0);
    }
    return getArticleByGroupType(offerTypeArticles, linebreak);
  }

  function getBatteryArticlesStoredEnergy(batteries) {
    let storedEnergyTotal = 0;
    batteries.forEach((battery) => {
      storedEnergyTotal += Number(battery.details['Opgeslagen energie'].value);
    });
    return numberToDecimalString(storedEnergyTotal) + ' ' + batteries[0].details['Opgeslagen energie'].suffix;
  }

  return {
    getArticlesData,
    getArticleByGroupType,
    getArticleByOfferType,
    getBatteryArticlesStoredEnergy,
  };
});
