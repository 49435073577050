<template>
  <v-container justify-center fluid class="py-0">
    <v-row>
      <v-bottom-navigation
        :active="navActive"
        grow
        horizontal
        elevation="2"
        class="w-full"
      >
        <nav-bar-bottom-btn name="installations" />
        <nav-bar-bottom-btn
          v-if="monitoringEnabled"
          name="monitoring"
          :navigates="false"
          :active="monitoringActive"
          @click="navToMonitoring"
        />
        <nav-bar-bottom-btn name="support" />

        <transition name="slide-up">
          <div
            v-if="showingNavBarMonitoring"
            class="position-absolute top-0 right-0 bottom-0 left-0 bg-background d-flex"
          >
            <v-btn class="flex-0-1-0" size="x-small" @click="hideNavBarMonitoring">
              <div>
                <v-icon icon="mdi-arrow-down" />
              </div>
            </v-btn>
            <nav-bar-bottom-btn
              v-for="routeName in monitoringRoutes"
              :key="'monitoring-' + routeName"
              :name="routeName"
              class="rounded-0"
            />
          </div>
        </transition>
      </v-bottom-navigation>
    </v-row>
  </v-container>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';
import NavBarBottomBtn from '@/components/NavBarBottomBtn.vue';
import { storeToRefs } from 'pinia';
import { useMonitoringGeneralStore } from '@/store/monitoringGeneral';

const route = useRoute();
const router = useRouter();

const showingNavBarMonitoring = ref(false);
const monitoringStore = useMonitoringGeneralStore();
const { getDevices } = monitoringStore;
const { monitoringEnabled } = storeToRefs(monitoringStore);

const showForRoutes = ref([
  'installations',
  'monitoring',
  'support',
]);

const monitoringRoutes = ref([
  'monitoring',
  'insights',
  'history',
]);

const navActive = computed(() => showForRoutes.value.includes(route.name) || monitoringRoutes.value.includes(route.name));
const monitoringActive = computed(() => monitoringRoutes.value.includes(route.name));

const navToMonitoring = () => {
  if (monitoringActive.value) {
    showNavBarMonitoring();
  } else {
    router.push({ name: 'monitoring' });
  }
};

const showNavBarMonitoring = () => {
  showingNavBarMonitoring.value = true;
};
const hideNavBarMonitoring = () => {
  showingNavBarMonitoring.value = false;
};

onMounted(getDevices);

watch(
  () => route.name,
  () => {
    if (monitoringRoutes.value.includes(route.name)) {
      showNavBarMonitoring();
    } else {
      hideNavBarMonitoring();
    }
  },
  { immediate: true },
);
</script>

<style scoped>
p {
    font-size: 0.9em;
    color: rgb(var(--v-theme-base400))
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.15s ease;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}
</style>
