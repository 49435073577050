import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useApi } from '@/composables/useApi';
import { getApiWsRoot } from '@/helpers/api';
import { getKeycloak } from '@/helpers/keycloak';
import { useSnackbarStore } from '@/store/snackbar';
import { useI18n } from 'vue-i18n';
import { useMonitoringGeneralStore } from '@/store/monitoringGeneral';

// keep the ws connection alive until this amount of time without user activity. 15 mins milliseconds.
const connectionLifetime = 15 * 60 * 1000;
const refreshInterval = 180000; // 3 min
const retryLimit = 5;
const retryInterval = 5 * 1000;

export const useMonitoringRealTimeStore = defineStore('monitoringRealTimeStore', () => {
  const { apiPost } = useApi();
  const { showError } = useSnackbarStore();
  const { t } = useI18n();

  const { currentDeviceId } = storeToRefs(useMonitoringGeneralStore());

  const loadingData = ref(true);
  const data = ref({});
  const socket = ref();
  const socketStatus = ref();

  const refreshIntervalId = ref();

  const lastViewed = ref();
  const closedByInactivity = ref(false);

  const closedByServer = ref(false);
  const shouldRetry = ref(true);
  const retries = ref(0);

  const socketClosed = computed(() => socketStatus.value === WebSocket.CLOSED);

  const currentSocketDeviceId = computed(() => {
    const params = new URLSearchParams(socket.value?.url.split('?')[1]);
    return params.get('deviceId');
  });

  async function initiateBroadcast() {
    lastViewed.value = Date.now();
    reset();

    const result = await enableBroadcast();

    if (!result) {
      showError(t('genericServerError'));
      return;
    }

    await connectBroadcast();

    refreshIntervalId.value = setInterval(keepAlive, refreshInterval);
  }

  function reset() {
    loadingData.value = true;
    unsubscribeBroadcast();
    closedByInactivity.value = false;
    closedByServer.value = false;
    shouldRetry.value = true;
    retries.value = 0;
  }

  /**
   * Keep ws connection alive.
   * If the last user activity is less than 15 minutes away. Send request to keep the device ws connection open.
   * Else end connection.
   * @returns {Promise<void>}
   */
  async function keepAlive() {
    if (lastViewed.value < Date.now() - connectionLifetime) {
      closedByInactivity.value = true;
      unsubscribeBroadcast();
      return;
    }

    await enableBroadcast();
  }

  async function enableBroadcast() {
    return await apiPost('monitoring/enableBroadcast', { deviceId: currentDeviceId.value });
  }

  async function connectBroadcast() {
    socket.value = new WebSocket(getApiWsRoot() + '/monitoring?accessToken=' + (await getKeycloak()).token + '&deviceId=' + currentDeviceId.value);

    // Connection opened
    socket.value.addEventListener('error', (event) => {
      console.error(event);
      socketStatus.value = socket.value?.readyState;
    });

    // Connection opened
    socket.value.addEventListener('open', () => {
      socketStatus.value = socket.value?.readyState;
    });

    // Listen for messages
    socket.value.addEventListener('message', (event) => {
      loadingData.value = false;

      if (event.data instanceof Blob) {
        const reader = new FileReader();

        reader.onload = () => {
          data.value = JSON.parse(reader.result);
        };

        reader.readAsText(event.data);

        return;
      }

      data.value = JSON.parse(event.data);
    });

    // Connection opened
    socket.value.addEventListener('close', () => {
      socketStatus.value = socket.value?.readyState;

      if (!shouldRetry.value) {
        return;
      }

      if (retries.value < retryLimit) {
        retries.value++;
        setTimeout(connectBroadcast, retryInterval);

        return;
      }

      closedByServer.value = true;
    });
  }

  function unsubscribeBroadcast() {
    shouldRetry.value = false;
    socket.value?.close();
    clearInterval(refreshIntervalId.value);
  }

  return {
    data,
    socket,
    socketClosed,
    currentSocketDeviceId,
    lastViewed,
    loadingData,
    closedByInactivity,
    closedByServer,
    initiateBroadcast,
    enableBroadcast,
    unsubscribeBroadcast,
  };
});
