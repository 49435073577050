import axios from 'axios';
import { getApiRoot } from '@/helpers/api';
import { getLocalstorageItem, setLocalstorageItem } from '@/helpers/localStorage';
import { getSubdomain } from '@/helpers/util';

export const setGoogleTagManager = async () => {
  const tagId = await getGoogleTagManagerId();
  if (tagId) {
    insertGoogleTagManager(tagId);
  }
};

const getGoogleTagManagerId = async () => {
  const subdomain = getSubdomain();
  if (getLocalstorageItem(subdomain + '.google-manager-tag-id')) {
    return getLocalstorageItem(subdomain + '.google-manager-tag-id');
  }

  let tagId = '';
  try {
    const response = await axios.get(
      getApiRoot() + '/portal/tag-id', {
        params: {
          subDomain: getSubdomain(),
        },
      },
    );
    if (response.status === 200) {
      tagId = response.data.data.tagId;
    }
  } catch (err) {
    console.log(err);
  }

  setLocalstorageItem(subdomain + '.google-manager-tag-id', tagId, 999);

  return tagId;
};

const insertGoogleTagManager = (tagId) => {
  window.initGTagManager(tagId);

  const noscript = document.querySelector('#google-tag-noscript');
  noscript.setAttribute('scr', 'https://www.googletagmanager.com/ns.html?id=' + tagId);
};
