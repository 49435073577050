<template>
  <v-app>
    <v-theme-provider>
      <push-notifications />
      <dialog-pwa />
      <nav-bar-side v-if="route.path !== '/' && userLoggedIn && !mobile" />
      <nav-bar-top v-if="route.path !== '/'" />
      <v-main :style="{background: $vuetify.theme.themes['lightTheme'].base0}">
        <component :is="route.meta.layout ?? 'BlankLayout'">
          <router-view />
        </component>
      </v-main>
      <nav-bar-bottom v-if="displayBottomNavBar && mobile" />
      <snack-bar />
    </v-theme-provider>
  </v-app>
</template>

<script setup>
import NavBarTop from '@components/NavBarTop.vue';
import NavBarBottom from '@components/NavBarBottom.vue';
import NavBarSide from '@components/NavBarSide.vue';
import SnackBar from '@components/SnackBar.vue';
import { useRoute } from 'vue-router';
import { onMounted, watchEffect, ref } from 'vue';
import {
  emptyLocalstorage,
  hasToken,
} from '@helpers/localStorage';
import { hasSubdomain } from './helpers/util';
import { useUserStore } from '@store/user';
import { useInstallationStore } from '@store/installations';
import { useOfferStore } from '@store/offers';
import { useOrderStore } from '@store/orders';
import { useDeliveryStore } from '@store/deliveries';
import { storeToRefs } from 'pinia';
import { useDisplay } from 'vuetify';
import { useI18n } from 'vue-i18n';
import { useRepairsStore } from './store/repairs';
import { useIssueStore } from './store/issues';
import PushNotifications from '@/components/PushNotifications.vue';
import { useAppStore } from '@/store/store';
import DialogPwa from '@/components/DialogPwa.vue';

const displayBottomNavBar = true;
const route = useRoute();
const { mobile } = useDisplay();

const appStore = useAppStore();
const { getAppConfig } = appStore;

const installationStore = useInstallationStore();
const { refreshInstallations } = installationStore;

const offerStore = useOfferStore();
const { getOfferStatuses } = offerStore;

const orderStore = useOrderStore();
const { getOrderStatuses } = orderStore;

const deliveryStore = useDeliveryStore();
const { getDeliveryStatuses } = deliveryStore;

const repairsStore = useRepairsStore();
const { getRepairStatuses } = repairsStore;

const issueStore = useIssueStore();
const { getIssueStatuses } = issueStore;

const userStore = useUserStore();
const { userLoggedIn } = storeToRefs(userStore);

const i18n = useI18n();
const language = ref(route.query?.lang); // initialize variable with change tracking capabilities from home?lang=en

watchEffect(() => {
  language.value = route.query?.lang;
  if (i18n.availableLocales.includes(language.value)) {
    i18n.locale.value = language.value;
  }
});

onMounted(async () => {
  try {
    if (hasSubdomain()) {
      await getAppConfig();
      await getOfferStatuses();
      await getOrderStatuses();
      await getDeliveryStatuses();
      await getRepairStatuses();
      await getIssueStatuses();
      if (hasToken()) {
        userLoggedIn.value = true;
        await refreshInstallations();
      }
    } else {
      emptyLocalstorage();
    }
  } catch (error) {
    console.log(error);
  }
});

</script>
