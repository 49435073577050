<template>
  <!--
        On mobile, user is logged in
        Left button, app bar title, right button
    -->
  <nav-bar-top-mobile v-if="mobile" />
  <nav-bar-top-desktop v-else />
  <component :is="modalComponent" v-model="modalActive" />
</template>

<script setup>
import { useTopNavBarStore } from '@store/topNavBar';
import { storeToRefs } from 'pinia';
import { useDisplay } from 'vuetify';
import NavBarTopDesktop from '@/components/navbartop/NavBarTopDesktop.vue';
import NavBarTopMobile from '@/components/navbartop/NavBarTopMobile.vue';

const { mobile } = useDisplay();

const store = useTopNavBarStore();

const {
  modalComponent,
  modalActive,
} = storeToRefs(store);
</script>
