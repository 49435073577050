<script setup>
import NavBarSideItem from '@/components/NavBarSideItem.vue';
import { computed, ref, watch } from 'vue';

const props = defineProps({
  groupName: {
    type: String,
    required: true,
  },
  children: {
    type: Array,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  selectedIcon: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  badge: {
    type: Boolean,
    required: false,
    default: false,
  },
  badgeColor: {
    type: String,
    required: false,
    default: 'primary',
  },
});

const emit = defineEmits(['activate']);

const activator = ref();
const active = computed(() => activator.value?.active);

const relatedRoutes = computed(() => props.children.map((childRoute) => childRoute.name));

watch(
  active,
  () => {
    if (active.value) {
      emit('activate', props.groupName);
    }
  },
  { immediate: true },
);
</script>

<template>
  <v-list-group class="side-item-group" :value="groupName">
    <template #activator="slotProps">
      <nav-bar-side-item
        v-bind="slotProps.props"
        ref="activator"
        :icon="icon"
        :selected-icon="selectedIcon"
        :title="title"
        :badge="badge"
        :badge-color="badgeColor"
        :related="relatedRoutes"
      />
    </template>

    <nav-bar-side-item
      v-for="childRoute in children"
      :key="'side-nav-' + childRoute.routeName"
      :route-name="childRoute.name"
      :icon="childRoute.icon"
      :selected-icon="childRoute.selectedIcon"
      :title="childRoute.title"
      :badge="childRoute.badge"
      :badge-color="childRoute.badgeColor"
    />
  </v-list-group>
</template>

<style scoped>
.side-item-group .v-list-group__items .v-list-item {
    padding-inline-start: calc(var(--indent-padding) - 16px) !important;
}
</style>
