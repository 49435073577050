<script setup>

import Logo from '@/components/PortalLogo.vue';
import SelectMonitoringDevice from '@/components/navbartop/SelectMonitoringDevice.vue';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useTopNavBarStore } from '@/store/topNavBar';
import { storeToRefs } from 'pinia';
import { useInstallationDocuments } from '@/composables/useInstallationDocuments';

const route = useRoute();
const router = useRouter();
const { download } = useInstallationDocuments();

const store = useTopNavBarStore();

const isRootPage = ref(true);

const { toggleModal } = store;
const {
  leftNav,
  leftNavCustomIcon,
  navTitle,
  rightNav,
  downloadDocument,
  backFunction,
} = storeToRefs(store);

watch(
  () => route.fullPath,
  () => {
    const routeParts = route.fullPath.split('/');
    isRootPage.value = routeParts.length === 2;
  },
  { immediate: true },
);
</script>

<template>
  <!--
        user is not logged in
        Displays an image as the title
    -->
  <v-app-bar
    v-if="!route.meta.requiresAuth"
    id="navbartop"
    :elevation="0"
    class="d-flex align-content-space-evenly px-2"
    color="secondary"
  >
    <v-app-bar-title class="pl-10">
      <logo />
    </v-app-bar-title>
  </v-app-bar>

  <!--
        user is logged in
    -->
  <v-app-bar
    v-else
    id="navbartop"
    :elevation="0"
    height="110"
  >
    <div class="d-flex justify-center align-center pt-16 w-100">
      <div style="min-width: 650px" class="d-flex justify-space-between">
        <div class="d-flex justify-start mx-0 flex-1-1-0">
          <template v-if="leftNav == 'back'">
            <v-btn
              size="small"
              variant="tonal"
              icon="mdi-arrow-left"
              @click="backFunction"
            />
          </template>
          <template v-else-if="leftNav === 'modal'">
            <v-btn
              size="small"
              variant="tonal"
              :icon="leftNavCustomIcon ?? 'mdi-information-outline'"
              @click="toggleModal"
            />
          </template>
        </div>
        <div class="d-flex justify-center">
          <div v-if="!isRootPage">
            <p class="small-text font-weight-medium">
              {{ navTitle }}
            </p>
          </div>
        </div>
        <div class="d-flex justify-end mx-0 flex-1-1-0">
          <template v-if="rightNav == 'options-close'">
            <div>
              <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-close"
              />
            </div>
          </template>
          <template v-else-if="rightNav == 'share-download'">
            <div>
              <v-btn
                size="small"
                variant="tonal"
                icon="mdi-tray-arrow-down"
                :loading="downloadDocument.downloading"
                @click="() => download(downloadDocument)"
              >
                <template #loader>
                  <v-progress-circular
                    color="base800"
                    size="20"
                    width="1"
                    indeterminate
                  />
                </template>
              </v-btn>
            </div>
          </template>
          <template v-else-if="rightNav == 'download'">
            <v-btn
              size="small"
              variant="tonal"
              icon="mdi-tray-arrow-down"
              :loading="downloadDocument.downloading"
              @click="() => download(downloadDocument)"
            >
              <template #loader>
                <v-progress-circular
                  color="base800"
                  size="20"
                  width="1"
                  indeterminate
                />
              </template>
            </v-btn>
          </template>
          <template v-else-if="rightNav === 'loyalty'">
            <v-btn
              size="x-small"
              variant="tonal"
              icon="mdi-gift-outline"
              @click="router.push({ path: '/loyalty' })"
            />
          </template>
          <template v-else-if="rightNav === 'monitoring'">
            <select-monitoring-device />
          </template>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<style scoped>
.small-text {
  font-size: 1.1rem !important;
}
</style>
