<script setup>
import { useDisplay } from 'vuetify';
import { useAppStore } from '@/store/store';
import { storeToRefs } from 'pinia';

const { mobile } = useDisplay();
const appStore = useAppStore();
const { pageLoading } = storeToRefs(appStore);
</script>

<template>
  <v-container class="fill-height" fluid>
    <v-row
      class="fill-height"
      :class="mobile ? 'px-3' : ''"
      no-gutters
    >
      <v-spacer v-if="!mobile" />

      <v-col v-if="pageLoading" class="d-flex justify-center align-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
          class="align-center mb-16"
        />
      </v-col>

      <v-col
        v-show="!pageLoading"
        :cols="mobile ? 12 : 5"
        :class="mobile ? 'px-0' : ''"
        align-center
        justify-center
        :style="mobile ? '' : 'min-width: 800px;'"
      >
        <slot />
      </v-col>

      <v-spacer v-if="!mobile" />
    </v-row>
  </v-container>
</template>
