import { createApp } from 'vue';
import { registerPlugins, registerLayouts } from '@/plugins';
import { registerChartJsComponents } from '@/plugins/chartjs';
import { initKeycloak } from '@/helpers/keycloak';
import { getSubdomain } from '@/helpers/util';
import { setGoogleTagManager } from '@/helpers/googleTagManager';
import App from './App.vue';

const capitalize = (str) => str.charAt(0).toUpperCase() + str.substring(1);

const getHostUrl = () => {
  const hostparts = window.location.href.split('happit.app');
  const hosturl = `${hostparts[0]}happit.app`;
  return import.meta.env.VITE_NAME === 'local' ? 'http://localhost:3000' : hosturl;
};

const getManifest = () => {
  const hosturl = getHostUrl();
  const manifest = {
    start_url: `${hosturl}/installations`,
    display: 'standalone',
    background_color: '#ffffff',
    lang: 'en',
    scope: `${hosturl}/`,
    theme_color: '#ffffff',
  };

  const subdomain = getSubdomain();
  const appTitle = capitalize(subdomain);
  manifest.name = appTitle;
  manifest.short_name = appTitle;
  manifest.description = appTitle;

  const icons = [];
  icons.push({
    src: `${hosturl}/${subdomain}.ico`,
    sizes: '16x16 32x32 48x48',
  });
  icons.push({
    src: `${hosturl}/${subdomain}_64.png`,
    sizes: '64x64',
    type: 'image/png',
  });
  icons.push({
    src: `${hosturl}/${subdomain}_192.png`,
    sizes: '192x192',
    type: 'image/png',
  });
  icons.push({
    src: `${hosturl}/${subdomain}.png`,
    sizes: '512x512',
    type: 'image/png',
    purpose: 'any',
  });

  manifest.icons = icons;
  const content = encodeURIComponent(JSON.stringify(manifest));
  return 'data:application/manifest+json,' + content;
};

const setIndexIcons = () => {
  const hosturl = getHostUrl();
  const subdomain = getSubdomain();
  document.querySelector('#icon').setAttribute('href', `${hosturl}/${subdomain}.ico`);
  document.querySelector('#icon_48').setAttribute('href', `${hosturl}/${subdomain}_48.png`);
  document.querySelector('#icon_512').setAttribute('href', `${hosturl}/${subdomain}_512.png`);
  document.querySelector('#apple_touch').setAttribute('href', `${hosturl}/${subdomain}_192.png`);
  document.querySelector('#touch_icon').setAttribute('href', `${hosturl}/${subdomain}_192.png`);
};

const setManifest = () => {
  const url = getManifest();
  document.querySelector('#my-manifest').setAttribute('href', url);
  document.querySelector('#app_title').innerHTML = capitalize(getSubdomain());

  setIndexIcons();
};

(async () => {
  await initKeycloak();
  await setGoogleTagManager();

  const app = createApp(App);
  registerPlugins(app);
  registerLayouts(app);

  app.mount('#app');

  registerChartJsComponents();

  setManifest();
})();
