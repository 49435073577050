<template>
  <v-img :src="logo" width="250" height="60" />
</template>

<script setup>
import { getApiRoot } from '@helpers/api';
import { setLocalstorageItem, getLocalstorageItem } from '@helpers/localStorage';
import { hasSubdomain, getSubdomain } from '@helpers/util';
import { onMounted, ref } from 'vue';
import axios from 'axios';

const logo = ref('');

onMounted(async () => {
  if (hasSubdomain()) {
    logo.value = getLocalstorageItem('logo');
    if (logo.value !== undefined) {
      return;
    }
    try {
      const response = await axios.get(
        getApiRoot() + '/portal/logo', {
          params: {
            subDomain: getSubdomain(),
          },
        },
      );
      if (response.status === 200) {
        logo.value = response.data.data.url;
        setLocalstorageItem('logo', logo.value);
      }
    } catch (err) {
      console.log(err);
    }
  }
});
</script>
