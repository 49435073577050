import i18n from '@i18n';

const { t } = i18n.global;

export function toLongDate(dbDate, includeTime = true) {
  if (dbDate === null || !Date.parse(dbDate)) {
    return t('inspectionDetail.noDate');
  }

  const dateOptions = {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
  };
  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };

  const dateObj = new Date(dbDate);
  if (!isNaN(dateObj)) {
    const dateString = dateObj.toLocaleDateString('nl-NL', dateOptions);
    const timeString = dateObj.toLocaleTimeString('nl-NL', timeOptions);
    if (timeString !== '00:00' && includeTime) {
      return [dateString, t('at'), timeString].join(' ');
    } else {
      return dateString;
    }
  } else {
    return t('inspectionDetail.noDate');
  }
}

export function toShortDate(dbDate) {
  if (dbDate === null || !Date.parse(dbDate)) {
    return t('inspectionDetail.noDate');
  }

  const dateOptions = {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
  };

  const dateObj = new Date(dbDate);
  if (!isNaN(dateObj)) {
    const dateString = dateObj.toLocaleDateString('nl-NL', dateOptions);
    return dateString;
  } else {
    return t('inspectionDetail.noDate');
  }
}

/**
 *
 * @param dbDate
 * @param {any} locale
 * @param options
 * @returns {*|string}
 */
export function toTimeString(dbDate, locale = [], options = {}) {
  if (dbDate === null || !Date.parse(dbDate)) {
    return t('inspectionDetail.noDate');
  }

  if (Object.keys(options).length === 0) {
    options = { hour12: false, hour: '2-digit', minute: '2-digit' };
  }

  return new Date(dbDate).toLocaleTimeString(locale, options);
}

export function getTimeDifferenceInDays(date1, date2) {
  const diffTime = date2 - date1;
  return Math.floor(diffTime / (1000 * 60 * 60 * 24));
}

/**
 * Takes a date string and converts it to a timestamp, interpreting the string as UTC.
 * @param dateString
 * @returns {number}
 */
export function dateStringToUTCTimestamp(dateString) {
  const date = new Date(dateString);

  return date.getTime() - (date.getTimezoneOffset() * 1000 * 60);
}
