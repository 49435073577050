export function getApiRoot() {
  let protocol = 'https';
  if (import.meta.env.VITE_NAME === 'local') {
    protocol = 'http';
  }
  return protocol + '://' + import.meta.env.VITE_HAPPERP_DOMAIN + ':' + import.meta.env.VITE_HAPPERP_PORT + '/api';
}
export function getApiWsRoot() {
  let protocol = 'wss';
  if (import.meta.env.VITE_NAME === 'local') {
    protocol = 'ws';
  }
  return protocol + '://' + import.meta.env.VITE_HAPPERP_DOMAIN + ':' + import.meta.env.VITE_HAPPERP_PORT + '/websockets';
}
