import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getKeycloak } from '@/helpers/keycloak';
import { getApiRoot } from '@/helpers/api';
import apiClient from '@/plugins/api-client';
import { getLocalstorageItem, setLocalstorageItem } from '@/helpers/localStorage';

export const useCustomerStore = defineStore('customerStore', () => {
  const customer = ref(undefined);

  async function getCustomerData() {
    try {
      const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
      const response = await apiClient.get(
        `${getApiRoot()}/customers/me`,
        { headers },
      );
      if (response.data.errors.length === 0) {
        customer.value = response.data.data;
      } else {
        throw new Error(response.data.errors);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  async function registerSubscription(subscription) {
    try {
      const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
      const response = await apiClient.post(
        `${getApiRoot()}/customers/registerSubscription`,
        { subscription },
        { headers },
      );

      if (response.data.errors.length > 0) {
        throw new Error(response.data.errors);
      }
      return response.data.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  function arePushNotificationsDeclined() {
    return getLocalstorageItem('PushNotificationsDeclined');
  }

  function declinePushNotifications() {
    setLocalstorageItem('PushNotificationsDeclined', true, 90);
  }

  function isPWADeclined() {
    return getLocalstorageItem('PWADeclined');
  }

  function declinePWA() {
    setLocalstorageItem('PWADeclined', true, 90);
  }

  return {
    customer,
    getCustomerData,
    registerSubscription,
    arePushNotificationsDeclined,
    declinePushNotifications,
    isPWADeclined,
    declinePWA,
  };
});
