<script setup>
import { useMonitoringGeneralStore } from '@/store/monitoringGeneral';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';

const monitoringStore = useMonitoringGeneralStore();
const { getDevices, selectDevice } = monitoringStore;
const { devices, currentDevice } = storeToRefs(monitoringStore);

onMounted(getDevices);
</script>

<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn
        variant="tonal"
        append-icon="mdi-menu"
        class="text-truncate"
        v-bind="props"
      >
        <slot>
          <span class="text-truncate" style="max-width: 130px">{{ currentDevice?.name }}</span>
        </slot>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(device, index) in devices"
        :key="'monitoring-device-' + index"
        class="cursor-pointer"
      >
        <v-list-item-title class="text-truncate text-uppercase" style="max-width: 150px" @click="() => selectDevice(device)">
          {{ device.name }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
