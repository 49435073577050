<template>
  <v-card>
    <v-navigation-drawer
      color="secondary"
      :order="-1"
      permanent
    >
      <v-list-item class="pt-10 px-5">
        <logo />
      </v-list-item>

      <v-list v-model:opened="open" class="px-5 pt-10">
        <template v-for="sidebarRoute in routes" :key="sidebarRoute.name">
          <nav-bar-side-item-group
            v-if="sidebarRoute.children"
            :group-name="sidebarRoute.groupName"
            :children="sidebarRoute.children"
            :icon="sidebarRoute.icon"
            :selected-icon="sidebarRoute.selectedIcon"
            :title="sidebarRoute.title"
            :badge="sidebarRoute.badge"
            :badge-color="sidebarRoute.badgeColor"
            @activate="activateGroup"
          />

          <nav-bar-side-item
            v-else
            :route-name="sidebarRoute.name"
            :icon="sidebarRoute.icon"
            :selected-icon="sidebarRoute.selectedIcon"
            :title="sidebarRoute.title"
            :badge="sidebarRoute.badge"
            :badge-color="sidebarRoute.badgeColor"
          />
        </template>
      </v-list>

      <template #append>
        <div class="pa-5">
          <nav-bar-side-item
            icon="mdi-logout"
            :title="$t('logout')"
            class="pa-5"
            @click.stop="logout"
          />
        </div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script setup>
import Logo from '@/components/PortalLogo';
import { useUserStore } from '@/store/user';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useNotificationStore } from '@/store/notifications';
import { computed, onMounted, ref } from 'vue';
import NavBarSideItemGroup from '@/components/NavBarSideItemGroup.vue';
import NavBarSideItem from '@/components/NavBarSideItem.vue';
import { useMonitoringGeneralStore } from '@/store/monitoringGeneral';

const i18n = useI18n();

const userStore = useUserStore();
const { logoutUser } = userStore;

const { hasNewNotifications } = storeToRefs(useNotificationStore());
const monitoringStore = useMonitoringGeneralStore();
const { getDevices } = monitoringStore;
const { monitoringEnabled } = storeToRefs(monitoringStore);

const open = ref(['monitoring']);

const routes = computed(() => ([
  {
    name: 'installations',
    icon: '$installations',
    selectedIcon: '$installations_selected_sidebar',
    title: i18n.t('bottomNav.installations'),
  },
  {
    name: 'loyalty',
    icon: 'mdi-gift-outline',
    selectedIcon: 'mdi-gift-outline',
    title: i18n.t('bottomNav.loyalty'),
  },
  ...(monitoringEnabled.value
    ? [{
        groupName: 'monitoring',
        icon: '$monitoring',
        selectedIcon: '$monitoring_selected',
        title: i18n.t('bottomNav.monitoring'),
        children: [
          {
            name: 'monitoring',
            icon: '$monitoring',
            selectedIcon: '$monitoring_selected',
            title: i18n.t('bottomNav.monitoring'),
          },
          {
            name: 'insights',
            icon: 'mdi-plus-circle-multiple-outline',
            selectedIcon: 'mdi-plus-circle-multiple-outline',
            title: i18n.t('bottomNav.insights'),
          },
          {
            name: 'history',
            icon: 'mdi-history',
            selectedIcon: 'mdi-history',
            title: i18n.t('bottomNav.history'),
          },
        ],
      }]
    : []),
  {
    name: 'support',
    icon: '$support',
    selectedIcon: '$support_selected',
    title: i18n.t('bottomNav.support'),
  },
  {
    name: 'account',
    icon: '$account',
    selectedIcon: '$account_selected',
    title: i18n.t('bottomNav.account'),
  },
  {
    name: 'notifications',
    icon: 'mdi-bell-outline',
    selectedIcon: 'mdi-bell-outline',
    title: i18n.t('bottomNav.notifications'),
    badge: hasNewNotifications.value,
  },
]));

const activateGroup = (group) => {
  open.value.push(group);
};

function logout() {
  logoutUser();
}

onMounted(getDevices);

</script>
