<template>
  <v-dialog v-model="isDialogOpen" width="auto" style="max-width: 800px">
    <v-card>
      <v-card-title>{{ $t('pwa.dialogTitle') }}</v-card-title>
      <v-card-text>
        {{ $t('pwa.dialogBody') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="decline">
          {{ $t('pwa.decline') }}
        </v-btn>
        <v-btn variant="elevated" color="primary" @click="accept">
          {{ $t('pwa.accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useCustomerStore } from '@/store/customer';

const customerStore = useCustomerStore();

let deferredPrompt = ref();
let isDialogOpen = ref(false);

onMounted(async () => {
  if (customerStore.isPWADeclined()) {
    return;
  }

  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    deferredPrompt.value = e;

    isDialogOpen.value = true;
  });
});

const decline = () => {
  isDialogOpen.value = false;
  customerStore.declinePWA();
};

const accept = async () => {
  isDialogOpen.value = false;

  deferredPrompt.value.prompt();
};
</script>
