<script setup>
import router from '@/router';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  routeName: {
    type: String,
    required: false,
    default: null,
  },
  icon: {
    type: String,
    required: true,
  },
  selectedIcon: {
    type: String,
    required: false,
    default: null,
  },
  title: {
    type: String,
    required: true,
  },
  badge: {
    type: Boolean,
    required: false,
    default: false,
  },
  badgeColor: {
    type: String,
    required: false,
    default: 'primary',
  },
  related: {
    type: Array,
    required: false,
    default: () => ([]),
  },
});

const route = useRoute();

const relatedRoutes = computed(() => ([props.routeName, ...props.related]));

const active = computed(() => route.matched.some((matched) => relatedRoutes.value.includes(matched.name) || matched.children.some((child) => (child.path === '' && relatedRoutes.value.includes(child.name)))));

const icon = computed(() => active.value ? props.selectedIcon ?? props.icon : props.icon);

function navigate() {
  if (props.routeName) {
    router.push({ name: props.routeName });
  }
}

defineExpose({
  active,
});
</script>

<template>
  <v-list-item
    :active="active"
    :title="title"
    ripple
    rounded="lg"
    @click="navigate()"
  >
    <template #prepend="{isActive}">
      <v-badge
        :model-value="badge ?? false"
        :color="badgeColor ?? 'primary'"
        location="top start"
        dot
      >
        <v-icon :color="isActive ? 'primary' : 'base400'" :icon="icon" />
      </v-badge>
    </template>
  </v-list-item>
</template>
